import React from "react";
import { Link } from "react-scroll";
import Pagetitle from "../elements/Pagetitle";
import TechnicalSkill from "../elements/TechnicalSkill";

const skillsData = [
  {
    id: 1,
    icon: "aws.png",
    title: "AWS",
    type: "Infrastructure",
    contentColor: "light",
  },
  {
    id: 2,
    icon: "terraform.png",
    title: "Terraform",
    type: "Infrastructure as code",
    contentColor: "light",
  },
  {
    id: 3,
    icon: "docker.png",
    title: "Docker",
    type: "Containers",
    contentColor: "light",
  },
  {
    id: 4,
    icon: "gitlab.png",
    title: "Gitlab",
    type: "CI/CD",
    contentColor: "light",
  },
  {
    id: 3,
    icon: "PowerShell.png",
    title: "PowerShell",
    type: "Infrastructure scripts",
    contentColor: "light",
  },
  {
    id: 4,
    icon: "shell.png",
    title: "Shell",
    type: "Infrastructure scripts",
    contentColor: "light",
  },
  {
    id: 5,
    icon: "postgre.png",
    title: "PostgreSQL",
    type: "Database",
    contentColor: "light",
  },
  {
    id: 6,
    icon: "oracle.png",
    title: "Oracle",
    type: "Database",
    contentColor: "light",
  },
  {
    id: 7,
    icon: "sql-server.png",
    title: "SQL Server",
    type: "Database",
    contentColor: "light",
  },
  {
    id: 8,
    icon: "DynamoDB.png",
    title: "DynamoDB",
    type: "Database",
    contentColor: "light",
  },
  {
    id: 9,
    icon: "python.png",
    title: "Python",
    type: "Programming",
    contentColor: "light",
  },
  {
    id: 10,
    icon: "node-js.png",
    title: "Node.js",
    type: "Programming",
    contentColor: "light",
  },
  {
    id: 10,
    icon: "java.png",
    title: "Java Spring",
    type: "Programming",
    contentColor: "light",
  },

  {
    id: 12,
    icon: "kafka.png",
    title: "Kafka",
    type: "Event / Messaging",
    contentColor: "light",
  },
  {
    id: 13,
    icon: "react.jpg",
    title: "React",
    type: "Front end",
    contentColor: "light",
  },

];

function Skills() {
  return (
    <section id="skills">
      <div className="container">
        <Pagetitle title="Stack technique" />
        <div className="row fix-spacing">
          {skillsData.map((skill) => (
            <div className="col-6 col-sm-4 col-md-3 col-lg-2 text-center" key={skill.id}>
              <TechnicalSkill skill={skill} />
            </div>
          ))}
        </div>
       </div>
    </section>
  );
}

export default Skills;
