import React from "react";
import { Link } from "react-scroll";
import Pagetitle from "../elements/Pagetitle";
import Service from "../elements/Service";

const servicesData = [
  {
    id: 1,
    icon: "images/architecte.png",
    title: "Architecte",
    content:
      "Concevoir un produit SI qui répond à vos besoins et qui s'intègre avec votre système existant. Collaborer avec une équipe produit, des développeurs, des experts techniques ou en cybersécurité de la conception à la réalisation. Ecouter, négocier, organiser, argumenter, rester pragmatique, réussir et savoir échouer vite, documenter, être persévérant font parti du quotidien de l'architecte.",
    color: "#6C6CE5",
    contentColor: "light",
  },
  {
    id: 2,
    icon: "images/devops.png",
    title: "Ingénieur DevSecOps",
    content:
      "Déployer votre produit rapidement pour réduire vos délais de mise en service. Passer du code en production en quelques minutes avec l'utilisation de chaînes de déploiement automatiques incluants des tests de sécurité. Adapter pour diminuer vos coûts et/ou gagner en performance. Utiliser du code d'infrastructure (déploiement cloud) pour sécuriser l'exploitation et déployer plus facilement de nouveaux produits ou environnements.",
    color: "#F9D74C",
    contentColor: "dark",
  },
  {
    id: 3,
    icon: "images/full-stack.png",
    title: "Développeur full stack",
    content:
      "Coder pour apprendre à des machines à manipuler des données structurées, non structurées, des évenements ou construire une API pour exposer ces données, répondre à une difficulté technique, d'obolescence, démontrer la faisabilité, pour améliorer la performance ou générer des revenus. Les écrans sont la face visible de votre produit, ils doivent être beaux et simples, instinctifs, rapides, sécurisés et s'adapter à tous les formats. Plus d'informations sur mon niveau par technologie dans la section stack technique",
    color: "#F97B8B",
    contentColor: "light",
  },
];

function Services() {
  return (
    <section id="services">
      <div className="container">
        <Pagetitle title="Services" />
        <div className="row fix-spacing">
          {servicesData.map((service) => (
            <div className="col-md-4" key={service.id}>
              <Service service={service} />
            </div>
          ))}
        </div>
        <div className="mt-5 text-center">
          <p className="mb-0">
            Vous avez une mission à me confier ?{" "}
            <Link
              className="colorpink pointer"
              to="section-contact"
              spy={true}
              smooth={true}
              duration={500}
            >
              Cliquez ici
            </Link>{" "}
            pour contacter! 👋
          </p>
        </div>
      </div>
    </section>
  );
}

export default Services;
