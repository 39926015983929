import React from "react";
import Pagetitle from "../elements/Pagetitle";
import Timeline from "../elements/Timeline";

const educationData = [
  {
    id: 1,
    title: "Formation architecture",
    years: "2019",
    content:
      "Architecte de la transformation digitale des entreprises - onepoint",
  },
  {
    id: 2,
    title: "Ecole d'ingénieur - ESIGELEC",
    years: "2009 - 2014",
    content:
      "Ecole d'ingénieurs généralistes, spécialité ingénieur d'affaires des systèmes d'information.",
  },
  {
    id: 3,
    title: "Lycée Léopold Sédar Senghor",
    years: "2007 - 2009",
    content:
      "Baccalauréat scientifique, option mathématiques et anglais. UNSS : Championnat de France de Triathlon",
  },
];

const experienceData = [
  {
    id: 1,
    title: "Architecte",
    years: "2023 - Present",
    content:
      "Responsable de l'architecture au sein d'un pôle dédié à la commercialisation et la production de l'offre de transport de gaz en France (GRTgaz). Conception des nouveaux produits, des évolutions majeures. Garant du respect du cadre technologique et des politiques de l'entreprise, du maintien à jour de la cartographie fonctionnelle et technique du parc applicatif (TOGAF). Travail au sein d'un contexte d'agilité à l'échelle Safe",
  },
  {
    id: 2,
    title: "Ingénieur IT",
    years: "2019 - 2023",
    content:
      "DevSecOps cloud dans le CCoE (Cloud Center of Excellence) de GRTgaz. Responsable de la migration de systèmes Linux/Windows et des adaptations d'architecture pour renforcer la disponibilité et la sécurité, la migration de bases de données (dont passage d'Oracle vers PostgreSQL), l'automatisation complète de l'infrastructure et du déploiement de l'application sur AWS. Utilisation de code d'infrastrucutre en Python et Terraform",
  },
  {
    id: 3,
    title: "Pilote Opérationnel d'exploitation",
    years: "2015 - 2019",
    content:
      "En charge du bon fonctionnement d'applications considérées comme essentielles à l'activité de l'entreprise (GRTgaz) avec gestion contractuelle des intervenants et astreinte régulière 24h/24 7j/7",
  },
  {
    id: 3,
    title: "Chef de projet",
    years: "2011 - 2015",
    content:
      "Rédaction des cahiers des charges techniques et fonctionnels pour le maintien en condition opérationnel d'un système prévisionnel",
  },
];

function Experiences() {
  return (
    <section id="experience">
      <div className="container">
        <Pagetitle title="Experience" />
        <div className="row">
          <div className="col-md-5">
            <div className="timeline edu bg-white rounded shadow-dark padding-30 overflow-hidden">
              {educationData.map((education) => (
                <Timeline key={education.id} education={education} />
              ))}
              <span className="line"></span>
            </div>
          </div>

          <div className="col-md-7">
            <div className="spacer d-md-none d-lg-none" data-height="30"></div>
            <div className="timeline exp bg-white rounded shadow-dark padding-30 overflow-hidden">
              {experienceData.map((experience) => (
                <Timeline key={experience.id} education={experience} />
              ))}
              <span className="line"></span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Experiences;
