import React from "react";

function TechnicalSkill({ skill }) {
  const { title, type, icon } = skill;
  const winWidth = window.innerWidth;
  
  return (
    <div className="skill-item">
      <div className="skill-info clearfix">
        <h4 className="mx-auto mb-0 mt-0">{title}</h4>
        <p className="mx-auto mb-3 mt-0">{type}</p>
        <span className="mx-auto">
          <img className="technicalSkillImg" src={"/icons/" + icon}/>
        </span>
      </div>
    </div>
  );
}

export default TechnicalSkill;
